import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";

class Navbar extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: pages } = data.allMarkdownRemark;
    return (
      <nav className="navbar" role="navigation" aria-label="main-navigation">
        <div className="container">
          <img alt="logo" src="/img/cropped-Dummaheader.jpg" />
          <div className="title">
            <h1>Flag in Distress</h1>
            <h2>Rants for a better America</h2>
          </div>
          <div id="navMenu" className="navbar-menu">
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/">
                Home
              </Link>
              {pages &&
                pages.map(({ node: page }) => (
                  <Link
                    className="navbar-item"
                    to={page.fields.slug}
                    key={page.fields.slug}
                  >
                    {page.frontmatter.title}
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query NavbarQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { frontmatter: { templateKey: { eq: "about-page" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Navbar data={data} />}
  />
);
