import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import { kebabCase } from "lodash";

class Sidebar extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    const months = posts.reduce((months, { node: post }) => {
      const bottomMonth = months[months.length - 1];
      if (bottomMonth == null || bottomMonth.slug !== post.frontmatter.slugDate)
        months.push({
          slug: post.frontmatter.slugDate,
          name: post.frontmatter.fullDate,
        });
      return months;
    }, []);

    const tags = posts.reduce((categories, { node: post }) => {
      post.frontmatter.tags.map((tag) => categories.add(tag));
      return categories;
    }, new Set());

    return (
      <div className="sidebar">
        <h3>Recent Posts</h3>
        <ul>
          {posts &&
            posts.slice(0, 5).map(({ node: post }) => (
              <li key={post.fields.slug}>
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
              </li>
            ))}
        </ul>
        <h3>Archives</h3>
        <ul>
          {months.map((month) => (
            <li key={month.slug}>
              <Link to={`/${month.slug}`}>{month.name}</Link>
            </li>
          ))}
        </ul>
        <h3>Categories</h3>
        <ul>
          {Array.from(tags).map((tag) => (
            <li key={tag}>
              <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
            </li>
          ))}
        </ul>
        <h3>Meta</h3>
        <ul>
          <li>
            <a href="/admin">Site Admin</a>
          </li>
          <li>
            <Link to="/feed">Entries feed</Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query SidebarQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                fullDate: date(formatString: "MMMM YYYY")
                slugDate: date(formatString: "YYYY/MM")
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Sidebar data={data} />}
  />
);
